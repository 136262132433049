<template>
  <div class="bt-cif pa-5">
    <Header title="Pengajuan Pembiayaan" titleIcon="mdi-note-plus-outline" class="mb-4 text-h6 font-weight-bold mb-5" />
    <v-container class="pa-0 pb-5">
      <div class="">
        <div
          class="w-full elevation-3 rounded-lg pa-5 mb-5"
          :style="{
            backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
          }"
        >
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  :items="list.hari_transaksi"
                  v-model="form.hari_transaksi"
                  label="Hari Transaksi"
                  required
                  :rules="[(v) => !!v || 'Hari Transaksi is required']"
                />
                <v-autocomplete
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  :items="list.cm"
                  v-model="form.cm_code"
                  label="Rembug"
                  required
                  :rules="[(v) => !!v || 'Rembug is required']"
                />
                <v-autocomplete
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  :items="list.cif"
                  v-model="form.cif_no"
                  label="ID Anggota"
                  required
                  :rules="[(v) => !!v || 'ID Anggota is required']"
                />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.nama" label="Nama Lengkap (Sesuai KTP)" disabled />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.tgl_lahir" label="Tanggal Lahir" disabled />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.usia" label="Usia" disabled />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.no_ktp" label="No KTP" disabled />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.no_hp" label="No HP" disabled />
                <v-textarea :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.alamat" label="Alamat" disabled rows="3" />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.rt_rw" label="RT/RW" disabled />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.desa" label="Desa" disabled />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.kecamatan" label="Kecamatan" disabled />
                <v-text-field :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'" autocomplete="off" outlined v-model="form.kabupaten" label="Kabupaten" disabled />
                <v-autocomplete
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  :items="list.jenis_pembiayaan"
                  v-model="form.jenis_pembiayaan"
                  label="Jenis Pembiayaan"
                  required
                  :rules="[(v) => !!v || 'Jenis Pembiayaan is required']"
                />
                <v-text-field
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  v-model="form.jumlah"
                  v-mask="thousandMask"
                  label="Jumlah Pembiayaan"
                  required
                  :rules="[(v) => !!v || 'Jumlah Pembiayaan is required']"
                />
                <v-text-field
                  type="number"
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  v-model="form.jangka_waktu"
                  label="Rencana Jangka Waktu"
                  required
                  :rules="[(v) => !!v || 'Rencana Jangka Waktu is required']"
                />
                <v-autocomplete
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  :items="list.periode_jangka_waktu"
                  v-model="form.periode_jangka_waktu"
                  label="Periode"
                  required
                  :rules="[(v) => !!v || 'Periode is required']"
                />
                <v-autocomplete
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  :items="list.allocation"
                  v-model="form.peruntukan"
                  label="Peruntukan"
                  required
                  :rules="[(v) => !!v || 'Peruntukan is required']"
                />
                <v-text-field
                  type="date"
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  v-model="form.tanggal_pengajuan"
                  label="Tanggal Pengajuan"
                  required
                  :rules="[(v) => !!v || 'Tanggal Pengajuan is required']"
                />
                <v-autocomplete
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  :items="list.business"
                  v-model="form.jenis_usaha"
                  label="Jenis Usaha"
                  required
                  :rules="[(v) => !!v || 'Jenis Usaha is required']"
                />
                <v-textarea
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  autocomplete="off"
                  outlined
                  v-model="form.keterangan"
                  label="Keterangan"
                  required
                  :rules="[(v) => !!v || 'Keterangan is required']"
                />
                <v-file-input
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  outlined
                  label="File KK"
                  v-model="form.file_foto_kk"
                  accept="image/*"
                  chips
                  prepend-icon="mdi-camera"
                  placeholder="Pilih File"
                />
                <v-file-input
                  :color="$vuetify.theme.dark ? 'background-color: white' : 'background-color: black'"
                  outlined
                  label="File Persetujuan"
                  v-model="form.file_foto_persetujuan"
                  accept="image/*"
                  chips
                  prepend-icon="mdi-camera"
                  placeholder="Pilih File"
                />
                <div class="mb-5 pt-5">
                  <div class="d-flex justify-space-between">
                    <label for="ttd_anggota" class="mb-2">TTD Anggota</label>
                    <v-btn text small color="orange" @click="clearTtd('ttd_anggota')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                  </div>
                  <VueSignaturePad
                    id="ttd_anggota"
                    width="100%"
                    height="150px"
                    ref="ttd_anggota"
                    :options="signatureOptions"
                    :style="{
                      border: $vuetify.theme.dark ? '1px solid #ccc' : '1px solid #666666',
                    }"
                  />
                </div>
                <div class="mb-5 pt-5">
                  <div class="d-flex justify-space-between">
                    <label for="ttd_saksi_1" class="mb-2">TTD Saksi 1</label>
                    <v-btn text small color="orange" @click="clearTtd('ttd_saksi_1')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                  </div>
                  <VueSignaturePad
                    id="ttd_saksi_1"
                    width="100%"
                    height="150px"
                    ref="ttd_saksi_1"
                    :options="signatureOptions"
                    :style="{
                      border: $vuetify.theme.dark ? '1px solid #ccc' : '1px solid #666666',
                    }"
                  />
                </div>
                <div class="mb-5 pt-5">
                  <div class="d-flex justify-space-between">
                    <label for="ttd_saksi_2" class="mb-2">TTD Saksi 2</label>
                    <v-btn text small color="orange" @click="clearTtd('ttd_saksi_2')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                  </div>
                  <VueSignaturePad
                    id="ttd_saksi_2"
                    width="100%"
                    height="150px"
                    ref="ttd_saksi_2"
                    :options="signatureOptions"
                    :style="{
                      border: $vuetify.theme.dark ? '1px solid #ccc' : '1px solid #666666',
                    }"
                  />
                </div>
                <div class="mb-5 pt-5">
                  <div class="d-flex justify-space-between">
                    <label for="ttd_saksi_3" class="mb-2">TTD Saksi 3</label>
                    <v-btn text small color="orange" @click="clearTtd('ttd_saksi_3')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                  </div>
                  <VueSignaturePad
                    id="ttd_saksi_3"
                    width="100%"
                    height="150px"
                    ref="ttd_saksi_3"
                    :options="signatureOptions"
                    :style="{
                      border: $vuetify.theme.dark ? '1px solid #ccc' : '1px solid #666666',
                    }"
                  />
                </div>
                <div class="mb-5 pt-5">
                  <div class="d-flex justify-space-between">
                    <label for="ttd_saksi_4" class="mb-2">TTD Saksi 4</label>
                    <v-btn text small color="orange" @click="clearTtd('ttd_saksi_4')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                  </div>
                  <VueSignaturePad
                    id="ttd_saksi_4"
                    width="100%"
                    height="150px"
                    ref="ttd_saksi_4"
                    :options="signatureOptions"
                    :style="{
                      border: $vuetify.theme.dark ? '1px solid #ccc' : '1px solid #666666',
                    }"
                  />
                </div>
                <div class="mb-5 pt-5">
                  <div class="d-flex justify-space-between">
                    <label for="ttd_tpl" class="mb-2">TTD TPL</label>
                    <v-btn text small color="orange" @click="clearTtd('ttd_tpl')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                  </div>
                  <VueSignaturePad
                    id="ttd_tpl"
                    width="100%"
                    height="150px"
                    ref="ttd_tpl"
                    :options="signatureOptions"
                    :style="{
                      border: $vuetify.theme.dark ? '1px solid #ccc' : '1px solid #666666',
                    }"
                  />
                </div>
                <div class="mb-5 pt-5">
                  <div class="d-flex justify-space-between">
                    <label for="ttd_ketua_kelompok" class="mb-2">TTD Ketua Kelompok</label>
                    <v-btn text small color="orange" @click="clearTtd('ttd_ketua_kelompok')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                  </div>
                  <VueSignaturePad
                    id="ttd_ketua_kelompok"
                    width="100%"
                    height="150px"
                    ref="ttd_ketua_kelompok"
                    :options="signatureOptions"
                    :style="{
                      border: $vuetify.theme.dark ? '1px solid #ccc' : '1px solid #666666',
                    }"
                  />
                </div>
                <div class="mb-5 pt-5">
                  <div class="d-flex justify-space-between">
                    <label for="ttd_ketua_kumpulan" class="mb-2">TTD Ketua Kumpulan</label>
                    <v-btn text small color="orange" @click="clearTtd('ttd_ketua_kumpulan')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                  </div>
                  <VueSignaturePad
                    id="ttd_ketua_kumpulan"
                    width="100%"
                    height="150px"
                    ref="ttd_ketua_kumpulan"
                    :options="signatureOptions"
                    :style="{
                      border: $vuetify.theme.dark ? '1px solid #ccc' : '1px solid #666666',
                    }"
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>

      <v-row>
        <v-col cols="6" class="pb-5 mb-5">
          <router-link to="/dtl/dashboard">
            <v-btn block class="purple lighten-1 white--text">Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn block class="purple lighten-1 white--text" @click="doSave" :disabled="!isFormValid"> Simpan </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "@/components/Header";

export default {
  name: "PengajuanPembiayaan",
  components: {
    Toast,
    Header,
  },
  data() {
    return {
      signatureOptions: {
        penColor: "#c0f",
      },
      list: {
        hari_transaksi: [
          {
            value: "1",
            text: "Senin",
          },
          {
            value: "2",
            text: "Selasa",
          },
          {
            value: "3",
            text: "Rabu",
          },
          {
            value: "4",
            text: "Kamis",
          },
        ],
        cm: [],
        cif: [],
        cifs: [],
        jenis_pembiayaan: [
          {
            value: "0",
            text: "Kelompok",
          },
          {
            value: "1",
            text: "Individu",
          },
        ],
        allocation: [],
        business: [],
        periode_jangka_waktu: [
          {
            value: "1",
            text: "Minggu",
          },
          {
            value: "2",
            text: "Bulan",
          },
        ],
      },
      form: {
        hari_transaksi: null,
        cm_code: null,
        branch_code: null,
        cif_no: null,
        nama: null,
        tgl_lahir: null,
        usia: 0,
        no_ktp: null,
        no_hp: null,
        alamat: null,
        rt_rw: null,
        desa: null,
        kecamatan: null,
        kabupaten: null,
        jenis_pembiayaan: null,
        jumlah: 0,
        jangka_waktu: null,
        periode_jangka_waktu: null,
        peruntukan: null,
        tanggal_pengajuan: new Date().toISOString().substr(0, 10),
        jenis_usaha: null,
        keterangan: null,
        file_foto_kk: null,
        foto_kk: null,
        file_foto_persetujuan: null,
        foto_persetujuan: null,
        ttd_anggota: null,
        ttd_saksi_1: null,
        ttd_saksi_2: null,
        ttd_saksi_3: null,
        ttd_saksi_4: null,
        ttd_tpl: null,
        ttd_ketua_kelompok: null,
        ttd_ketua_kumpulan: null,
      },
      isFormValid: true,
      alert: {
        show: false,
        msg: "",
      },
      overlay: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    "form.hari_transaksi": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.getInfoRembug();
        }
      },
      immediate: true,
    },
    "form.cm_code": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.getInfoMember();
        }
      },
      immediate: true,
    },
    "form.cif_no": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.setInfoMember();
        }
      },
      immediate: true,
    },
    overlay: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.overlay = true;
        } else {
          this.overlay = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...helper,
    clearTtd(field) {
      this.form[field] = null;
      this.$refs[field].undoSignature();
    },
    async doSave() {
      if (!this.$refs.form.validate()) return;

      const signatures = [this.$refs.ttd_anggota, this.$refs.ttd_saksi_1, this.$refs.ttd_saksi_2, this.$refs.ttd_saksi_3, this.$refs.ttd_saksi_4, this.$refs.ttd_tpl, this.$refs.ttd_ketua_kelompok, this.$refs.ttd_ketua_kumpulan];

      signatures.forEach((signature, index) => {
        const { isEmpty: isEmptySignature, data: signatureData } = signature.saveSignature();
        this.form[`ttd_${["anggota", "saksi_1", "saksi_2", "tpl", "ketua_kelompok", "ketua_kumpulan"][index]}`] = isEmptySignature ? null : signatureData;
      });

      this.form.foto_kk = this.form.file_foto_kk ? await this.fileToBase64(this.form.file_foto_kk) : null;

      this.form.foto_persetujuan = this.form.file_foto_persetujuan ? await this.fileToBase64(this.form.file_foto_persetujuan) : null;

      this.overlay = true;
      let payload = new FormData();
      payload.append("branch_code", this.form.branch_code);
      payload.append("cif_no", this.form.cif_no);
      payload.append("usia", this.form.usia);
      payload.append("jenis_pembiayaan", this.form.jenis_pembiayaan);
      payload.append("jumlah", this.form.jumlah);
      payload.append("jangka_waktu", this.form.jangka_waktu);
      payload.append("periode_jangka_waktu", this.form.periode_jangka_waktu);
      payload.append("peruntukan", this.form.peruntukan);
      payload.append("tanggal_pengajuan", this.form.tanggal_pengajuan);
      payload.append("keterangan", this.form.keterangan);
      payload.append("foto_kk", this.form.foto_kk);
      payload.append("foto_persetujuan", this.form.foto_persetujuan);
      payload.append("ttd_anggota", this.form.ttd_anggota);
      payload.append("ttd_saksi_1", this.form.ttd_saksi_1);
      payload.append("ttd_saksi_2", this.form.ttd_saksi_2);
      payload.append("ttd_saksi_3", this.form.ttd_saksi_3);
      payload.append("ttd_saksi_4", this.form.ttd_saksi_4);
      payload.append("ttd_tpl", this.form.ttd_tpl);
      payload.append("ttd_ketua_kelompok", this.form.ttd_ketua_kelompok);
      payload.append("ttd_ketua_kumpulan", this.form.ttd_ketua_kumpulan);
      payload.append("fa_code", this.user.fa_code);
      try {
        const req = await services.transactionPengajuan(payload, this.user.token);
        if (req.data.status === true) {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };

          setTimeout(() => {
            this.$router.push(`/pembiayaan/dashboard`);
          }, 1500);
        } else {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async getInfoRembug() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("hari_transaksi", this.form.hari_transaksi);
      try {
        const req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const cm =
              req.data.data.map((item) => {
                return {
                  value: item.cm_code,
                  text: item.cm_name,
                };
              }) || [];
            this.list.cm = cm;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },

    async getInfoMember() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("cm_code", this.form.cm_code);
      try {
        const req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          const cif =
            req.data.data.map((item) => {
              return {
                value: item.cif_no,
                text: item.nama,
              };
            }) || [];

          this.list.cif = cif;
          this.list.cifs = req.data.data || [];
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async setInfoMember() {
      const row = this.list.cifs.find((item) => item.cif_no === this.form.cif_no);
      Object.assign(this.form, {
        branch_code: row.branch_code || null,
        cif_no: row.cif_no || null,
        nama: row.nama || null,
        tgl_lahir: row.tgl_lahir || null,
        usia: row.usia || null,
        no_ktp: row.no_ktp || null,
        no_hp: row.no_hp || null,
        alamat: row.alamat || null,
        rt_rw: row.rt_rw || null,
        desa: row.desa || null,
        kecamatan: row.kecamatan || null,
        kabupaten: row.kabupaten || null,
        jenis_usaha: row.jenis_usaha || null,
      });
    },

    async getInfoAllocation() {
      this.overlay = true;
      try {
        const req = await services.infoAllocation("", this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const allocation =
              req.data.data.map((item) => {
                return {
                  value: item.allocation_code,
                  text: item.allocation_name,
                };
              }) || [];
            this.list.allocation = allocation;
          } else {
            this.alert = {
              show: true,
              msg: "Peruntukan tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async getInfoBusiness() {
      this.overlay = true;
      try {
        const req = await services.infoBusiness("", this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const business =
              req.data.data.map((item) => {
                return {
                  value: item.business_code,
                  text: item.business_name,
                };
              }) || [];
            this.list.business = business;
          } else {
            this.alert = {
              show: true,
              msg: "Jenis Usaha tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
  },
  mounted() {
    this.getInfoAllocation();
    this.getInfoBusiness();
  },
};
</script>
